import axios from 'axios'
import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import { cloneDeep, isEmpty } from 'lodash'
import { v4 as uuidV4 } from 'uuid'
import { displayError } from './app'
import cinemasAPI from '../common/client/cinemasSearch'

const { CancelToken } = axios
let source = axios.CancelToken.source()

const initialState = {
  data: {
    standby_devices: [],
    auditoriums: []
  },
  loading: false,
  error: false,
  deviceModels: []
}

/* -- PostMessageAPI for iframe -- */
const sendPostMessageToParentFrame = data => {
  const payload = { ...data, auditoriums: data.auditoriums.map(({ screenId, ...keep }) => keep) }
  window.parent.postMessage({ type: 'UPDATED_FACILITY_DEVICES', payload }, '*')
}

/* -- Constants -- */
const SET_DEVICES = 'facilityDevices/SET_DEVICES'
const SET_LOADING = 'facilityDevices/SET_LOADING'
const SET_ERROR = 'facilityDevices/SET_ERROR'
const SET_DEVICE_MODELS = 'facilityDevices/SET_DEVICE_MODELS'

/* -- Actions -- */
const setLoading = bool => {
  return {
    type: SET_LOADING,
    payload: bool
  }
}

const setDevices = payload => {
  return { type: SET_DEVICES, payload }
}

const setDeviceError = () => {
  return {
    type: SET_ERROR
  }
}

const setDeviceModels = data => {
  return {
    type: SET_DEVICE_MODELS,
    payload: data
  }
}

/* -- API Calls -- */
export function fetchDeviceByFacilityId(id) {
  return (dispatch, getState) => {
    if (!getState().theatres.loading) {
      dispatch(setLoading(true))
      cinemasAPI
        .get(`/facilities/${id}/devices`)
        .then(response => {
          const auditoriums = response.data.auditoriums
            .map(auditorium => ({
              ...auditorium,
              screenId: auditorium.uuid
            }))
            .sort((a, b) => Number(a.number) - Number(b.number))
          const result = { ...response.data, auditoriums }
          dispatch(setDevices(result))
          dispatch(setLoading(false))
        })
        .catch(() => {
          dispatch(displayError(I18n.t('errors.fetchDevices')))
          dispatch(setDeviceError())
        })
    }
  }
}

export function fetchDeviceModels() {
  return dispatch => {
    cinemasAPI
      .get(`/device_models`)
      .then(response => {
        const result = response.data.map(item => {
          const { name, id, uuid, is_dci: dci, translations, device_type: code, ...rest } = item
          return {
            id,
            name,
            value: uuid,
            label: name,
            translations,
            data: {
              ...rest,
              device_model_id: id,
              code,
              model_id: uuid,
              specification: dci ? 'DCI' : 'Non DCI'
            }
          }
        })
        dispatch(setDeviceModels(result))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchDevices')))
      })
  }
}

export function fetchDeviceRoles(deviceModel) {
  return async () => {
    return cinemasAPI.get(`/device_models/${deviceModel}/device_roles`).then(response => {
      return response.data.device_roles?.map(item => ({
        ...item,
        value: `${item.name} - ${item.description}`
      }))
    })
  }
}

export function checkSerialNumber(number, model) {
  if (source) {
    source.cancel()
    source = CancelToken.source()
  }
  return () => {
    return cinemasAPI.get(
      `/device_models/device_certificate_status?serial=${number}&model=${model}`,
      { cancelToken: source.token }
    )
  }
}

export function createDevice({ devices, screenId }) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = { ...facilityDevicesData }
    if (screenId) {
      payload.auditoriums = payload.auditoriums.map(i => {
        if (i.screenId === screenId) {
          /* 1. if same device present in standby devices remove it before inserting the new one. */
          payload.standby_devices = payload.standby_devices.filter(d => {
            return !devices.some(d2 => {
              return (
                isEmpty(d.suites) &&
                d2.model_id === d.model_id &&
                d2.serial_number === d.serial_number
              )
            })
          })
          /* 2. if same device present in unmapped devices, remove it before inserting the new one. */
          const filteredDevices = i.devices.filter(d1 => {
            return !devices.some(d2 => {
              return (
                isEmpty(d1.suites) &&
                d2.model_id === d1.model_id &&
                d2.serial_number === d1.serial_number
              )
            })
          })
          /* 3. Add the new device. */
          return { ...i, devices: [...filteredDevices, ...devices] }
        }
        return i
      })
    } else {
      payload.standby_devices = [...payload.standby_devices, ...devices]
    }

    dispatch(setDevices(payload))
    sendPostMessageToParentFrame(payload)
  }
}

export function deleteDevice({ device, screenId, suite }) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = cloneDeep(facilityDevicesData)
    /* 
      if device belongs to a screen, then remove the device from payload.auditorium
      otherwise remove it from payload.standby_devices.
    */
    if (screenId) {
      const screenIndex = payload.auditoriums.findIndex(i => i.screenId === screenId)
      if (screenIndex !== -1) {
        const deviceIndex = payload.auditoriums[screenIndex].devices.findIndex(
          d => d.uuid === device.uuid
        )
        if (deviceIndex !== -1) {
          /* if device have more than one suite, remove its suite entry. otherwise remove the device completely. */
          if (payload.auditoriums[screenIndex].devices[deviceIndex].suites?.length > 1 && suite) {
            payload.auditoriums[screenIndex].devices[deviceIndex].suites = payload.auditoriums[
              screenIndex
            ].devices[deviceIndex].suites.filter(s => Number(s.suite_number) !== Number(suite))
          } else {
            payload.auditoriums[screenIndex].devices.splice(deviceIndex, 1)
          }
        }
      }
    } else {
      payload.standby_devices = payload.standby_devices.filter(d => d.uuid !== device.uuid)
    }

    dispatch(setDevices(payload))
    sendPostMessageToParentFrame(payload)
  }
}

export function updateStandbyDevice({ previous, current }) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = { ...facilityDevicesData }
    payload.standby_devices = payload.standby_devices.map(d => {
      if (d.uuid === previous.uuid) {
        return { ...d, ...current }
      }
      return d
    })
    dispatch(setDevices(payload))
    sendPostMessageToParentFrame(payload)
  }
}

export function updateDevice({ screenId, previous, current }) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = { ...facilityDevicesData }
    payload.auditoriums = payload.auditoriums.map(i => {
      if (i.screenId === screenId) {
        return {
          ...i,
          devices: i.devices.map(d => {
            if (d.uuid === previous.uuid) {
              return { ...d, ...current }
            }
            return d
          })
        }
      }
      return i
    })
    dispatch(setDevices(payload))
    sendPostMessageToParentFrame(payload)
  }
}

export function moveDeviceToStandby({ device, screenId, suite }) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = cloneDeep(facilityDevicesData)
    /* 1. Remove device from screen (auditorium) */
    const screenIndex = payload.auditoriums.findIndex(i => i.screenId === screenId)
    if (screenIndex !== -1) {
      const deviceIndex = payload.auditoriums[screenIndex].devices.findIndex(
        d => d.uuid === device.uuid
      )
      if (deviceIndex !== -1) {
        /* if device have more than one suite, remove its suite entry. otherwise remove the device completely. */
        if (payload.auditoriums[screenIndex].devices[deviceIndex].suites?.length > 1 && suite) {
          payload.auditoriums[screenIndex].devices[deviceIndex].suites = payload.auditoriums[
            screenIndex
          ].devices[deviceIndex].suites.filter(s => Number(s.suite_number) !== Number(suite))
        } else {
          payload.auditoriums[screenIndex].devices.splice(deviceIndex, 1)
        }
      }
    }
    /* 2. Add the device into standby */
    const deviceAlreadyExistsInStandBy =
      payload.standby_devices.findIndex(s => s.uuid === device.uuid) !== -1
    let newDevice
    const deviceRole =
      suite && Array.isArray(device.suites)
        ? device.suites.find(s => Number(s.suite_number) === Number(suite))?.device_role
        : device.device_role
    if (deviceAlreadyExistsInStandBy) {
      const uuid = uuidV4()
      newDevice = { ...device, suites: null, device_role: deviceRole, uuid }
    } else {
      newDevice = { ...device, suites: null, device_role: deviceRole }
    }
    payload.standby_devices = [...payload.standby_devices, newDevice]

    dispatch(setDevices(payload))
    sendPostMessageToParentFrame(payload)
  }
}

export function updateFacilityCertificates(data) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = cloneDeep(facilityDevicesData)

    let deviceExists = false
    payload.standby_devices = payload.standby_devices.map(device => {
      if (
        device.device_model_id === data.device_model_id &&
        device.serial_number === data.serial_number
      ) {
        deviceExists = true
        return { ...device, has_certificate: data.has_certificate, certificate: data.certificate }
      }
      return device
    })
    payload.auditoriums = payload.auditoriums.map(screen => {
      const devices = screen.devices.map(device => {
        if (
          device.device_model_id === data.device_model_id &&
          device.serial_number === data.serial_number
        ) {
          deviceExists = true
          return { ...device, has_certificate: data.has_certificate, certificate: data.certificate }
        }
        return device
      })
      return { ...screen, devices }
    })
    if (!deviceExists) {
      payload.standby_devices = [...payload.standby_devices, { ...data, uuid: uuidV4() }]
    }

    dispatch(setDevices(payload))
    sendPostMessageToParentFrame(payload)
  }
}

export function handlePostMessages(data) {
  return (dispatch, getState) => {
    const facilityDevicesData = getState().facilityDevices.data
    const payload = cloneDeep(facilityDevicesData)
    switch (data.type) {
      case 'edit_existing_screen': {
        const screenIndex = payload.auditoriums.findIndex(i => i.uuid === data.screen.screenUuid)
        if (screenIndex !== -1) {
          payload.auditoriums[screenIndex] = {
            ...payload.auditoriums[screenIndex],
            number: Number(data.screen.screenNumber),
            name: data.screen.screenName,
            display_name: data.screen.screenName
          }
          dispatch(setDevices(payload))
          sendPostMessageToParentFrame(payload)
        }
        break
      }
      case 'edit_new_screen': {
        const screenIndex = payload.auditoriums.findIndex(i => i.screenId === data.screen.screenId)
        if (screenIndex !== -1) {
          payload.auditoriums[screenIndex] = {
            ...payload.auditoriums[screenIndex],
            number: Number(data.screen.screenNumber),
            name: data.screen.screenName,
            display_name: data.screen.screenName
          }
        } else {
          payload.auditoriums = [
            ...payload.auditoriums,
            {
              screenId: data.screen.screenId,
              number: Number(data.screen.screenNumber),
              name: data.screen.screenName,
              display_name: data.screen.screenName,
              devices: []
            }
          ]
        }
        dispatch(setDevices(payload))
        sendPostMessageToParentFrame(payload)
        break
      }
      case 'remove_new_screen': {
        payload.auditoriums = payload.auditoriums.filter(i => i.screenId !== data.screen.screenId)
        dispatch(setDevices(payload))
        sendPostMessageToParentFrame(payload)
        break
      }
      case 'FACILITY_DEVICES': {
        const auditoriums = data.payload.auditoriums
          .map(auditorium => {
            const devices = auditorium.devices.map(device => ({
              ...device,
              uuid: device.uuid ?? uuidV4()
            }))
            return {
              ...auditorium,
              devices,
              screenId: auditorium.uuid ?? uuidV4()
            }
          })
          .sort((a, b) => Number(a.number) - Number(b.number))
        const result = { ...data.payload, auditoriums }
        dispatch(setDevices(result))
        break
      }
      case 'FACILITY_DEVICES_CERTIFICATES': {
        const standby_devices = data.payload.standby_devices.map(device => ({
          ...device,
          uuid: device.uuid ?? uuidV4()
        }))
        const auditoriums = data.payload.auditoriums.map(auditorium => {
          const devices = auditorium.devices.map(device => ({
            ...device,
            uuid: device.uuid ?? uuidV4()
          }))
          return {
            ...auditorium,
            devices,
            screenId: auditorium.uuid ?? uuidV4()
          }
        })
        const result = { ...data.payload, standby_devices, auditoriums }
        dispatch(setDevices(result))
        break
      }
      default:
        break
    }
  }
}

/* -- Reducers -- */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICES: {
      const result = {}
      if (!isEmpty(action.payload)) {
        result.data = { $set: action.payload }
      }
      return update(state, result)
    }
    case SET_LOADING:
      return update(state, { loading: { $set: action.payload } })
    case SET_ERROR:
      return update(state, { error: { $set: true }, loading: { $set: false } })
    case SET_DEVICE_MODELS:
      return update(state, { deviceModels: { $set: action.payload } })
    default:
      return state
  }
}
